<script setup>
import { useRouter } from "vue-router";
import { auth } from "@/store/auth";
const authStore = auth();
const router = useRouter();
function handleComecar() {
  if (!authStore.token) {
    router.push({ name: "FormPdv" });
  } else {
    router.push({ name: "Painel" });
  }
}
</script>

<template>
  <section class="px-2 my-4 mobile position-relative">
    <div class="banner-mobile">
      <h1>Bem-Vindo Estabelecimento parceiro!</h1>
      <p>
        Os abridores feitos pra você ajudar a vender mais, e ainda ganhar
        premiar seu time com prêmios e vantagens!
      </p>

      <p class="text-abridor">Conheça o novo parceiro dos garçons!</p>
    </div>
    <button
      class="btn-main bg-primary rounded-pill"
      @click.prevent="handleComecar"
    >
      Se você já foi indicado, <strong>clique aqui para começar!</strong>
    </button>
  </section>
  <section class="bg-dark py-5 position-relative desktop">
    <div class="container d-flex justify-content-center">
      <div>
        <img
          class="img-fluid"
          src="../assets/img/banner-home.svg"
          alt="banner"
        />
      </div>

      <button
        class="btn-main bg-primary rounded-pill"
        @click.prevent="handleComecar"
      >
        Se você já foi indicado, <strong>clique aqui para começar!</strong>
      </button>
    </div>
  </section>
</template>

<style scoped>
.btn-main {
  padding: 12px 30px;
  position: absolute;
  min-height: 80px;
  font-size: 25px;
  bottom: -40px;
}

.mobile .btn-main {
  bottom: -130px;
}

h1 {
  font-size: 30px;
  color: #000218;
}

.mobile {
  display: none;
}
@media (max-width: 768px) {
  section.mobile {
    margin-bottom: 155px !important;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.banner-mobile {
  background-image: url(../assets/img/banner-mobile.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  max-width: 413px;
  height: 415px;
  margin: auto;
  position: relative;
}
.banner-mobile h1::before {
  content: "";
  display: block;
  height: 2.5px;
  width: 30px;
  background-color: #fffd38;
  margin-bottom: 10px;
}
.banner-mobile h1 {
  font-size: 23px;
  max-width: 143px;
}
.banner-mobile p {
  max-width: 240px;
  font-size: 13px;
  font-weight: 700;
}

.text-abridor {
  max-width: 169px !important;
  font-size: 15px !important;
  position: absolute;
  bottom: 120px;
  left: 174px;
}

@media (max-width: 414px) {
  .banner-mobile p {
    max-width: 230px;
    font-weight: 700;
  }

  .btn-main {
    font-size: 18px;
  }
}
</style>
