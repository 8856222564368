<script setup>
import { ref } from "vue";
import Ajuda from "../../components/Ajuda.vue";

const form = ref({
  nome_estabelecimento: "",
  cnpj: "",
  cep: "",
  rua: "",
  numero: "",
  complemento: "",
  email: "",
  telefone_estabelecimento: "",
  responsavel_nome: "",
  periodo: {
    manha: { aberto: false, nGarcons: "" },
    tarde: { aberto: false, nGarcons: "" },
    noite: { aberto: false, nGarcons: "" },
  },
});
</script>
<template>
  <div class="container">
    <div class="area-form mt-4">
      <h1 class="text-center mb-4">Dados do PDV:</h1>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.nome_estabelecimento"
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Nome do estabelecimento"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.cnpj"
          v-maska="'##.###.###/####-##'"
          type="text"
          class="form-control"
          placeholder="CNPJ"
          @blur="validaCnpjCpf(form.cnpj)"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.cep"
          v-maska="'#####-###'"
          type="text"
          class="form-control"
          placeholder="CEP"
          @blur="buscaCep"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.rua"
          type="text"
          class="form-control"
          placeholder="AV. Nações Unidas 18801"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.numero"
          type="text"
          class="form-control"
          placeholder="Número"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.complemento"
          type="text"
          class="form-control"
          placeholder="Complemento"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.email"
          type="email"
          class="form-control"
          placeholder="E-mail"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.telefone_estabelecimento"
          v-maska="'(##) #####-####'"
          type="text"
          class="form-control"
          placeholder="Telefone com DDD"
        />
      </div>
      <p class="text-bolder h5 text-center my-4">
        Períodos os quais seu estabelecimento está aberto e os garçons do turno:
      </p>
      <div class="row periodo">
        <div
          class="col-6 d-flex align-items-center justify-content-between mb-2"
        >
          <p class="text-bold me-3">Manhã</p>
          <select
            v-model="form.periodo.manha.aberto"
            placeholder="Selecione"
            class="form-control"
            name="n_garcom"
          >
            <option :value="false">Fechado</option>
            <option :value="true">Aberto</option>
          </select>
        </div>
        <div class="col-6">
          <input
            v-model="form.periodo.manha.nGarcons"
            type="number"
            min="0"
            class="form-control"
            placeholder="Nº de Garçons"
          />
        </div>
        <div
          class="col-6 d-flex align-items-center justify-content-between mb-2"
        >
          <p class="text-bold me-3">Tarde</p>
          <select
            v-model="form.periodo.tarde.aberto"
            placeholder="Selecione"
            class="form-control"
            name="n_garcom"
          >
            <option :value="false">Fechado</option>
            <option :value="true">Aberto</option>
          </select>
        </div>
        <div class="col-6">
          <input
            v-model="form.periodo.tarde.nGarcons"
            type="number"
            min="0"
            class="form-control"
            placeholder="Nº de Garçons"
          />
        </div>
        <div
          class="col-6 d-flex align-items-center justify-content-between mb-2"
        >
          <p class="text-bold me-3">Noite</p>
          <select
            v-model="form.periodo.noite.aberto"
            placeholder="Selecione"
            class="form-control"
            name="n_garcom"
          >
            <option :value="false">Fechado</option>
            <option :value="true">Aberto</option>
          </select>
        </div>
        <div class="col-6">
          <input
            v-model="form.periodo.noite.nGarcons"
            type="number"
            min="0"
            class="form-control"
            placeholder="Nº de Garçons"
          />
        </div>
      </div>

      <Ajuda />
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 30px;
  color: #000218;
}

.area-form {
  max-width: 400px;
  margin: auto;
}
</style>
