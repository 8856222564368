<template>
    <div
      class="d-flex flex-column mt-7 justify-content-center align-items-center"
    >
      <form class="form-login">
        <h1 class="text-center mb-4">CONFIRME O EMAIL E CADASTRE A NOVA SENHA</h1>
        <div class="input-group input-group-lg mb-3">
          <input
            v-model="form.email"
            type="text"
            class="form-control"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder="E-mail"
            @keyup.enter="handleLogin"
          />
        </div>
        <div class="input-group input-group-lg mb-3">
          <input
            v-model="form.password"
            type="password"
            class="form-control"
            aria-label="password"
            aria-describedby="basic-addon2"
            placeholder="Senha"
            @keyup.enter="handleLogin"
          />
        </div>
       
        <button
          type="button"
          class="btn w-100 rounded-pill"
          @click.prevent="handleChangePassword"
        >
          <div
            v-if="loading"
            style="width: 1rem; height: 1rem"
            class="spinner-border text-secondary"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Entrar</span>
        </button>
      
      </form>
   
    </div>
</template>

<script setup>
import { reactive, ref,onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
// import { preloaderStore } from "@/store/preloaderStore";

import { auth } from "@/store/auth";
const authStore = auth();
// const preloader = preloaderStore();

const router = useRouter();
const route = useRoute();

const loading = ref(false);

const form = reactive({
  email: "",
  password: "",
});

async function handleChangePassword() {
  const token = route.params.token;
  const { email, password } = form;

  try {
    const res = await authStore.ActionNewPassword({ email, token, password });
    if (res.status) {
      await window.__Swal.fire({
        icon: "success",
        text: res.message,
      });

      router.push({ name: "Login" });
    } else if (!res.status) {
      window.__Swal.fire({
        icon: "error",
        text: res.message,
      });
    }
  } catch (error) {
    if (error.status !== 401) {
      if (error.data) {
        window.__Swal.fire({
          icon: "error",
          title: error.data.error.title || "Oops...",
          text: error.data.error.message,
        });
      } else {
        await window.__Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        });
      }
    }
  }
}

async function checkToken() {
  try {
    const token = route.params.token;
    const res = await authStore.ActionValidateToken({ token });
    if (!res.status) {
      await window.__Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.message,
      });
      router.push({ name: "Login" });
    }
  } catch (error) {
    console.log(error);
  }
}

onMounted(()=>{
  checkToken();
})
</script>

<style scoped>
h1 {
  font-size: 30px;
  color: #000218;
}
.form-login {
  width: 100%;
  max-width: 500px;
}

.btn {
  padding: 0.75rem;
  font-size: 16px;
  background-color: #272727;
  color: #fff;
}
.btn.btn-n-entra {
  background-color: #f1f2f3;
  color: #747980;
}
</style>
