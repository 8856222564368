import { defineStore } from 'pinia'
import { http } from '@/services/api';

import { validaCpfCnpj } from "@/utils/validacpfCnpj";
import { preloaderStore } from "@/store/preloaderStore";
import { auth } from "@/store/auth";
import router from '../router'
import { useRoute } from 'vue-router';
let preloader, authStore

setTimeout(() => {
    preloader = preloaderStore();
    authStore = auth();

}, 100);

export const useCadastroPdv = defineStore('cadastroPdv', {
    state: () =>
    ({
        disabled: {
            cnpj: false,
            wifi: false,
            numero: false,
            rua: false,
            complemento: false,
            cep: false,
            nome_estabelecimento: false,
            telefone_estabelecimento: false,


        },
        isMobile: false,
        showInstrucoes: false,
        loadingCep: false,
        loading: false,
        cadastroSuccess: false,
        confirmDados: false,
        selectedStep: 1,
        request_signature_key: '',
        form: {
            estabelecimento: {
                nome_estabelecimento: "",
                cnpj: "",
                cep: "",
                rua: "",
                numero: "",
                complemento: "",
                email: "",
                telefone_estabelecimento: "",
                wifi: 'Não'
            },
            periodo: {
                manha: { aberto: false, nGarcons: "" },
                tarde: { aberto: false, nGarcons: "" },
                noite: { aberto: false, nGarcons: "" },
            },
            responsavel: {
                nome: "",
                telefone: "",
                email: "",
                password: "",
                cpf: "",
            },
            aceite_termo: false,
        },
        dadosSucesso: {}
    }),
    actions: {

        ActionCleanState() {
            this.loadingCep = false
            this.loading = false
            this.cadastroSuccess = false
            this.selectedStep = 1
            this.form = {
                estabelecimento: {
                    nome_estabelecimento: "",
                    cnpj: "",
                    cep: "",
                    rua: "",
                    numero: "",
                    complemento: "",
                    email: "",
                    telefone_estabelecimento: "",
                },
                periodo: {
                    manha: { aberto: false, nGarcons: "" },
                    tarde: { aberto: false, nGarcons: "" },
                    noite: { aberto: false, nGarcons: "" },
                },
                responsavel: {
                    nome: "",
                    telefone: "",
                    email: "",
                    password: "",
                    cpf: "",
                },
                aceite_termo: false,
            }
        },

        handleisMobile() {
            this.isMobile = window.innerWidth < 768;
        },

        buscaCep() {
            const cep = this.form.estabelecimento.cep
                .replace(/-/g, "")
                .replace(/\//g, "");
            if (cep.length !== 8)
                return window.__Swal.fire({
                    title: "Atenção",
                    icon: "warning",
                    text: "confira o cep digitado!",
                });

            this.loadingCep = true;
            const options = {
                method: "GET",
                mode: "cors",
                cache: "default",
            };
            if (cep.length === 8) {
                fetch(`https://viacep.com.br/ws/${cep}/json/`, options).then((r) => {
                    r.json().then((data) => {
                        if (!data.erro) {
                            const { logradouro } = data;
                            this.form.estabelecimento.rua = logradouro;
                            this.loadingCep = false;
                        } else {
                            window.__Swal.fire({
                                text: "Cep não encontrado, verifique e tente novamente!",
                                icon: "warning",
                            });
                            this.form.estabelecimento.cep = "";

                            this.loadingCep = false;
                        }
                    });
                });
            }

            this.loadingCep = false;
        },

        checkNomeCompleto() {
            let valido = this.form.responsavel.nome.trim().split(' ').length > 1

            if (!valido) {
                window.__Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    text: 'Preencher o nome completo',
                });
            }
        },

        validaCnpjCpf(doc) {
            let valido = validaCpfCnpj(
                doc.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "")
            );

            if (!valido) {
                if (this.selectedStep === 1) {
                    this.form.estabelecimento.cnpj = "";
                } else {
                    this.form.responsavel.cpf = "";
                }
                window.__Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    text: `${this.selectedStep === 1 ? "CNPJ" : "CPF"
                        } inválido`,
                });
            }
        },

        limpaMask(str) {
            return str ? str.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/\(/g, "").replace(" ", "") : ''
        },

        async handleCadastro() {
            if (this.selectedStep === 1) {
                this.selectedStep = 2;
            } else if (this.selectedStep === 2) {
                document.body.style.overflow = 'hidden'
                this.confirmDados = true
            } else if (this.selectedStep === 3) {
                try {
                    preloader.setPreloader(true); let formCadastro = JSON.parse(JSON.stringify(this.form));
                    formCadastro.periodo.manha.nGarcons === ""
                        ? (formCadastro.periodo.manha.nGarcons = 0)
                        : null;
                    formCadastro.periodo.tarde.nGarcons === ""
                        ? (formCadastro.periodo.tarde.nGarcons = 0)
                        : null;
                    formCadastro.periodo.noite.nGarcons === ""
                        ? (formCadastro.periodo.noite.nGarcons = 0)
                        : null;
                    formCadastro.responsavel.cpf = this.limpaMask(formCadastro.responsavel.cpf)
                    formCadastro.estabelecimento.cnpj = this.limpaMask(formCadastro.estabelecimento.cnpj)
                    console.log(formCadastro);
                    const res = await this.sendForm(formCadastro)

                    if (res.success) {
                        this.request_signature_key = res.data.request_signature_key
                        this.selectedStep = 4;
                    }
                    console.log(res)


                } catch (error) {
                    console.log(error)
                } finally {
                    preloader.setPreloader(false);
                }
            } else if (this.selectedStep === 4) {
                console.log(this.form);
                this.selectedStep = 5;
            } else {
                // loading.value = true;
                // try {
                //   await authStore.ActionDoLogin(form);
                //   await authStore.ActionGetProfile();
                //   router.push({ name: "Dash" });
                // } catch (error) {
                //   console.log(error);
                // } finally {
                //   loading.value = false;
                // }
            }
        },

        sendForm(payload) {
            const url = 'register'
            return http.post(url, payload).then((r) => {
                console.log(r.data)
                authStore.user = r.data.data
                authStore.token = r.data.token
                return r.data
            })
        },

        handleFinish() {
            this.ActionCleanState()
            router.push({ name: "Login" });
        },

        getProtocol() {
            const url = 'getProtocol'
            return http.post(url, { id: authStore.user.id }).then((r) => {
                console.log(r.data)
                this.dadosSucesso = r.data
                return r.data
            })
        },


        // aqui vai validar o tokendps
        async Validartoken() {


            const route = useRoute()
            const token = route.params.token

            if (!token) router.push({ name: 'Home' })
            try {
                const url = 'checkCnpj'
                const r = await http.post(url, { cnpj: token })


                if (r?.code) {
                    console.log(r)
                    if (r?.code == 404) {
                        window.__Swal.fire({
                            text: r.data.msg,
                            icon: "warning",
                        });
                        router.push({ name: 'Home' })
                    } else if (r?.code == 401) {
                        window.__Swal.fire({
                            text: r.data.msg,
                            icon: "warning",
                        });
                        router.push({ name: 'Login' })
                    }
                }
                if (r?.data) {
                    console.log(r.data)

                    this.form.estabelecimento.cep = r?.data?.estabelecimento?.cep;
                    this.form.estabelecimento.rua = r?.data?.estabelecimento?.address;
                    this.form.estabelecimento.wifi = r?.data?.estabelecimento?.hasWifi;
                    this.form.estabelecimento.cnpj = r?.data?.estabelecimento?.cnpj;
                    this.form.estabelecimento.complemento = r?.data?.estabelecimento?.complement;
                    this.form.estabelecimento.nome_estabelecimento = r?.data?.estabelecimento?.name;
                    this.form.estabelecimento.numero = r?.data?.estabelecimento?.numbers;
                    this.form.estabelecimento.telefone_estabelecimento = this.phoneMask(r?.data?.estabelecimento?.phone);
                    this.disabled.cep = !!this.form.estabelecimento.cep;
                    this.disabled.rua = !!this.form.estabelecimento.rua;
                    // this.disabled.wifi = !!this.form.estabelecimento.wifi;
                    this.disabled.cnpj = !!this.form.estabelecimento.cnpj;
                    this.disabled.complemento = !!this.form.estabelecimento.complemento;
                    this.disabled.nome_estabelecimento = !!this.form.estabelecimento.nome_estabelecimento;
                    this.disabled.numero = !!this.form.estabelecimento.numero;
                    this.disabled.telefone_estabelecimento = !!this.form.estabelecimento.telefone_estabelecimento;
                }


            } catch (error) {
                console.log(error)
                router.push({ name: 'Home' })
            }
        },

        phoneMask(value) {
            if (!value) return ""
            if (value.length <= 11) {
                value = value.replace(/\D/g, '')
                value = value.replace(/(\d{2})(\d)/, "($1) $2")
                value = value.replace(/(\d)(\d{4})$/, "$1-$2")
            }
            return value
        },

        editForm() {
            this.selectedStep = 1
            this.confirmDados = false
            document.body.style.overflow = 'auto'
        },
        confirmForm() {
            this.confirmDados = false
            this.selectedStep = 3
            document.body.style.overflow = 'auto'
        }
    },
})