<script setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { auth } from "@/store/auth";
import { useCadastroPdv } from "@/store/cadastroPdv";

const authStore = auth();
const route = useRoute();
const cadastradoPdv = useCadastroPdv();
const router = useRouter();
const showMenu = ref(false);

function handleLogout() {
  cadastradoPdv.ActionCleanState();
  authStore.ActionSingnout();
  router.push({ name: "Home" });
}

function handleEscape(e) {
  if (e.which === 27) {
    showMenu.value = false;
  }
}

watch(
  () => route.name,
  () => {
    showMenu.value = false;
  }
);
watch(
  () => showMenu.value,
  () => {
    if (showMenu.value) {
      window.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
    } else {
      window.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "auto";
    }
  }
);
</script>

<template>
  <header
    class="py-1"
    :class="{
      ['bg-primary']:
        cadastradoPdv.isMobile ||
        (route.name !== 'FormPdv' && route.name !== 'Login') ||
        cadastradoPdv.selectedStep === 4,
    }"
  >
    <div
      :class="`d-flex ${
        cadastradoPdv.selectedStep === 4
          ? 'justify-content-center'
          : 'justify-content-between'
      } align-items-center container`"
    >
      <button @click.prevent="showMenu = true">
        <img class="menu-icon" src="../assets/img/menu.svg" alt="" />
      </button>
      <div class="flex-1 text-center">
        <button @click.prevent="router.push({ name: 'Home' })">
          <img
            v-if="
              (route.name !== 'FormPdv' && route.name !== 'Login') ||
              cadastradoPdv.selectedStep === 4
            "
            class="logo-header"
            src="../assets/img/logo-header2.svg"
            alt="logo"
          />
        </button>
      </div>

      <button
        v-if="
          authStore.token && route.name !== 'FormPdv' && route.name !== 'Login'
        "
        class="btn-login rounded-pill"
        @click.prevent="handleLogout"
      >
        Sair
      </button>
      <router-link
        v-if="route.name !== 'Login' && !authStore.token"
        to="/login"
        class="btn-login rounded-pill"
        >Entrar</router-link
      >
    </div>

    <div
      v-if="showMenu"
      class="open-menu bg-primary d-flex justify-content-center pt-4"
    >
      <div class="menu-area text-center position-relative">
        <button class="fechar-menu" @click.prevent="showMenu = false">
          <img src="../assets/img/x.svg" alt="Fechar" />
        </button>
        <button @click.prevent="router.push({ name: 'HomeV2' })">
          <img src="../assets/img/logo-header2.svg" alt="logo" />
        </button>
        <ul class="mt-5">
          <!-- <li>
            <router-link :to="{ name: 'FormPdv' }">
              Fui indicado e quero cadastrar!
            </router-link>
          </li> -->
          <!-- <li v-if="route.name !== 'Painel'">
            <router-link :to="{ name: 'HomeV2' }">
              Home-2
            </router-link>
          </li> -->
          <li v-if="route.name !== 'Painel'">
            <router-link :to="{ name: 'Como funciona' }">
              Como funciona
            </router-link>
          </li>
          <li v-if="route.name !== 'Painel'">
            <router-link
              :to="{ name: `${authStore.token ? 'Painel' : 'Login'}` }"
            >
              Acessar o painel
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Contato' }"> Fale conosco </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <nav
    v-if="authStore.token && route.name !== 'FormPdv' && route.name !== 'Login'"
    class="bg-dark"
  >
    <div class="container">
      <ul class="d-flex flex-wrap justify-content-center gap-5 py-3 submenu">
        <!-- <li><router-link :to="{ name: 'Painel' }">Painel</router-link></li>
        <li>
          <router-link :to="{ name: 'DadosPdv' }">Dados do PDV</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Documentos' }">Documentos</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Contato' }">Fale conosco</router-link>
        </li> -->
      </ul>
    </div>
  </nav>
</template>

<style scoped>
.btn-login {
  border: solid 2px #000218;
  width: 118px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.open-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
}

.menu-area {
  width: 90%;
  max-width: 600px;
}

ul,
li {
  list-style: none;
  margin: 0;
}
.menu-area li {
  padding: 8px;
  font-size: 30px;
  font-weight: bold;
}
.menu-area li:not(:last-child) {
  border-bottom: solid 1px #000218;
}

.fechar-menu {
  position: absolute;
  z-index: 1010;
  right: -20px;
}

nav a {
  color: white;
  min-width: 105px;
  display: block;
  text-align: center;
  transition: all 0.2s;
}
nav a:hover,
.router-link-exact-active {
  color: #000218 !important;
  transform: scale(1.1) !important;
  opacity: 0.8;
  /* color: white !important; */
}
.submenu a:hover,
.submenu .router-link-exact-active {
  color: #fff !important;
  transform: scale(1.1) !important;
  opacity: 0.8;
  /* color: white !important; */
}

@media (max-width: 768px) {
  .menu-icon {
    width: 130px;
  }
}
@media (max-width: 480px) {
  .menu-icon {
    width: 100px;
  }
}
@media (max-width: 438px) {
  .logo-header {
    width: 110px;
  }
}
</style>
