<script setup>
import { useRoute } from "vue-router";
const route = useRoute();
</script>
<template>
  <section class="container content-area pb-5">
    <div class="d-flex justify-content-center">
      <h1 class="mb-4">Como funciona</h1>
    </div>
    <div class="d-flex justify-content-center">
      <div>
        <div class="text-center">
          <iframe
            :class="
              route.name === 'Como funciona'
                ? 'video-iframe'
                : 'video-iframe-modal'
            "
            src="https://www.youtube.com/embed/oYmCzEIwDSs0?start=25"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <h3 class="mb-0">Como funciona para o Bar?</h3>
          <p class="h5">Veja como o estabelecimento pode participar.</p>
        </div>
        <div class="mt-5 text-center">
          <iframe
            :class="
              route.name === 'Como funciona'
                ? 'video-iframe'
                : 'video-iframe-modal'
            "
            src="https://www.youtube.com/embed/oYmCzsEIwDS0?start=25"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <h3 class="mb-0">Como funciona para o Garçom?</h3>
          <p class="h5">Veja como o garçom pode participar.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.video-iframe-modal {
  width: 315px;
  height: 192px;
}
.video-iframe {
  width: 560px;
  height: 315px;
}
h1 {
  font-size: 30px;
  color: #fff;
  background-color: #000;
  display: inline-block;
  padding: 5px 15px;
}

.content-area {
  margin-top: 80px;
}

@media (max-width: 768px) {
}

@media (max-width: 600px) {
  .video-iframe {
    width: 400px;
  }
}
@media (max-width: 490px) {
  .video-iframe {
    width: 315px;
    height: 192px;
  }
}
</style>
