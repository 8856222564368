import { defineStore } from 'pinia'
import * as storage from "./storage";
import { http } from '@/services/api';


export const auth = defineStore('auth', {
    state: () =>
    ({
        user: {},
        token: ''
    }),
    actions: {
        ActionDoLogin(payload) {
            return http.post('login', payload).then(r => {
                this.ActionSetToken(r.data.data.token)
                this.user = r.data.data
                return r.data
            })
        },

        ActionGetProfile() {
            return http.post('profile').then(r => this.ActionSetUser(r.data))
        },

        ActionForgotPassword(payload) {
            const url = "resetPassword"
            return http.post(url, payload)
        },

        ActionValidateToken(payload) {
            const url = 'validateTokenReset'
            return http.post(url, payload).then(r => r.data)
        },

        ActionNewPassword(payload) {
            const url = 'newPassword'
            return http.post(url, payload).then(r => r.data)

        },

        ActionCheckToken() {
            const token = storage.getLocalToken();
            if (this.token) {
                return Promise.resolve(this.token);
            } else if (!token) {
                return Promise.reject(new Error("Token Inválido"));
            } else {
                this.ActionSetToken(token);
                // this.ActionSetUser(storage.getLocalUsuario());
                return Promise.resolve(token);
            }
        },
        ActionSetUser(payload) {
            this.user = payload;
        },
        ActionSetToken(payload) {
            storage.setLocalToken(payload);
            storage.setHeaderToken(payload);
            this.token = payload
        },
        ActionSingnout() {
            storage.setHeaderToken("");
            storage.deleteLocalToken();
            storage.deleteLocalUsuario();
            storage.deleteLocalPlan();
            this.ActionSetUser({});
            this.ActionSetToken("");
        },

    },
})