import { createRouter, createWebHistory } from "vue-router";


import Login from "../pages/Login/Login.vue";
import RecoverAuth from "../pages/Login/RecoverAuth.vue";
import FormPdv from "../pages/FormPdv/Form.vue";
import Home from "../pages/Home";
import ComoFunciona from "../pages/ComoFunciona";
import Painel from "../pages/Painel";
import DadosPdv from "../pages/DadosPdv";
import Documentos from "../pages/Documentos";
import Contato from "../pages/Contato";

import HomeV2 from "../pages/HomeV2";
import PageGarcom from "../pages/PageGarcom";

// import { getLocalUsuario, getLocalToken, setHeaderToken } from "../store/storage";
// import { auth } from "@/store/auth";
// const authStore = auth();

const routes = [
  //Login
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: 'layout-form'
    }
  },
  // {
  //   path: "/cadastro-pdv",
  //   name: "FormPdvToken",
  //   component: FormPdv,
  //   meta: {
  //     layout: 'layout-form'
  //   }
  // },
  {
    path: "/cadastro-pdv/:token",
    name: "FormPdv",
    component: FormPdv,
    meta: {
      layout: 'layout-form'
    }
  },
  {
    path: "/painel",
    name: "Painel",
    component: Painel,
    meta: {
      // auth: true
    }
  },
  {
    path: "/dados-pdv",
    name: "DadosPdv",
    component: DadosPdv,
    meta: {
      // auth: true
    }
  },
  {
    path: "/documentos",
    name: "Documentos",
    component: Documentos,
    meta: {
      // auth: true
    }
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contato,
  },

  {
    path: "/garcom",
    name: "PageGarcom",
    component: PageGarcom,
    meta: {
      layout: 'LayoutDefaultV2'
    }
  },
  {
    path: "/home-v2",
    name: "HomeV2",
    component: HomeV2,
    meta: {
      layout: 'LayoutDefaultV2'
    }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },

  {
    path: "/como-funciona",
    name: "Como funciona",
    component: ComoFunciona,
  },

  {
    path: "/recuperar-senha/:token",
    name: "Recuperar senha",
    component: RecoverAuth,
  },

  {
    path: "/:pathMath(.*)*",
    redirect: { name: "HomeV2" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

// router.beforeEach(async (to, from, next) => {
//   let isLogged = getLocalUsuario();
//   let token = getLocalToken()
//   if (typeof isLogged !== "object") {
//     isLogged = JSON.parse(isLogged);
//   }

//   // if (isLogged != null && isLogged.id != undefined) {
//   //   await authStore.ActionCheckToken();
//   // }

//   if (!token && to.meta.auth) {
//     setHeaderToken('')
//     next("/");
//   } else {
//     next(true);
//   }
// });

export default router;
