<template>
  <div
    style="min-height: 100vh !important; padding-bottom: 160px"
    class="bg-white flex-1 position-relative"
    :class="{ container: !cadastradoPdv.isMobile }"
  >
    <div class="d-flex justify-content-center content h-auto">
      <div class="flex-1 h-auto">
        <Header />
        <div class="img-banner-mobile">
          <img
            class="img-banenr-mobile"
            src="../assets/img/img-form-mobile.png"
            alt="banner"
          />
        </div>
        <router-view :class="{ container: cadastradoPdv.isMobile }" />
      </div>
      <div v-if="cadastradoPdv.selectedStep !== 4" class="img-banner">
        <img class="img-fluid" src="../assets/img/banner.png" alt="banner" />
      </div>
    </div>
  </div>
  <Footer class="footer-form" />
</template>

<script setup>
// import { useRouter } from "vue-router";
// import { auth } from "../../store/auth";
// const authStore = auth();
import { useCadastroPdv } from "@/store/cadastroPdv";

import Footer from "../components/Footer.vue";
// import { useRoute } from "vue-router";
import Header from "../components/Header.vue";
const cadastradoPdv = useCadastroPdv();

// const route = useRoute();
</script>

<style scoped>
.img-banner {
  width: 477px;
  height: auto;
}
/* .footer-form {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
} */
.img-banner-mobile {
  display: none;
}

@media (max-width: 768px) {
  .img-banenr-mobile {
    width: 100%;
    height: auto;
  }
  .img-banner {
    display: none;
  }
  .img-banner-mobile {
    display: block;
    border-top: 6px solid #000;
  }
}
</style>
