<script setup>
import { ref, onMounted } from "vue";
import { useCadastroPdv } from "../../store/cadastroPdv";
import { preloaderStore } from '@/store/preloaderStore'
const preloader = preloaderStore()

const cadastradoPdv = useCadastroPdv();

// const aceite_ctt_comodato = ref(false);
const widget = ref(null);

async function run() {
  preloader.setPreloader(true);
  await wait(10000)
  preloader.setPreloader(false);

  let container = document.getElementById("containerClickSign");
  container.classList.add("fullScreen");
  container.classList.add("bg-white");
  setTimeout(() => {
    let iframe = document.getElementsByTagName("iframe");
    iframe[0].style.height = "auto";
    iframe[0].style.minHeight = "100vh";
    console.log(iframe[0].style.height);
  }, 10);
  if (widget.value) {
    widget.value.unmount();
  }

  // eslint-disable-next-line no-undef
  widget.value = new Clicksign(cadastradoPdv.request_signature_key);

  widget.value.endpoint = process.env.VUE_APP_CLICKSIGN;
  widget.value.origin = process.env.VUE_APP_BASE_URL;
  widget.value.mount("containerClickSign");

  // eslint-disable-next-line no-unused-vars
  widget.value.on("loaded", function (ev) {
    console.log("loaded!");
  });
  // eslint-disable-next-line no-unused-vars
  widget.value.on("signed", async function (ev) {
    console.log("assinou!");
    console.log(ev);
    try {
      await cadastradoPdv.getProtocol();
      cadastradoPdv.selectedStep = 5;
    } catch (error) {
      console.log(error);
    }
  });
  widget.value.on("resized", function () {
    console.log("resized!");
  });
}

function wait(timeMs) {
      return new Promise(resolve => {
        setTimeout(resolve, timeMs)
      })
    }

onMounted(() => {  
  run();
});
</script>
<template>
  <div id="containerClickSign" class=""></div>

  <!-- <h1 class="text-center mb-4">Contrato de comodato dos abridores.</h1> -->
  <!-- <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacus
    tellus, vehicula et lorem quis, egestas imperdiet dui. Integer vitae
    convallis diam. In scelerisque turpis in fermentum posuere. Integer pharetra
    arcu convallis, finibus ex in, blandit diam. Proin tincidunt diam lectus, eu
    scelerisque augue tempor nec. Fusce tempus dolor nisl, at sodales ante
    iaculis congue. Pellentesque non libero quis leo imperdiet euismod quis ut
    velit. Quisque nec posuere mauris. Vestibulum nec ex laoreet, vestibulum
    velit sed, porta neque. Aenean vel rutrum ipsum, id rutrum nunc. Vestibulum
    tincidunt, risus eget egestas aliquet, sapien est vulputate turpis,
    fermentum feugiat erat metus eu urna. Donec blandit tristique nisi. Proin
    felis ex, dapibus sit amet ipsum vel, finibus porttitor sem. Pellentesque
    tellus nisl, rhoncus in mi eu, condimentum consequat quam. Vivamus id
    bibendum odio. Pellentesque ullamcorper gravida erat at rhoncus. Vivamus
    pretium semper quam, vitae auctor risus aliquam porta. Vestibulum et elit
    vitae ligula aliquet accumsan. Donec mollis mollis diam non accumsan. Nunc
    sed est vitae turpis pulvinar suscipit sagittis sed risus. Proin facilisis
    nunc quis eros finibus, a faucibus mauris porta. Donec luctus, mauris ut
    maximus sodales, odio mi sodales tortor, in eleifend turpis nisi et ligula.
    Sed ut pulvinar quam, ut pellentesque dolor. Sed ut faucibus nibh, sit amet
    luctus justo. Vivamus a eros vitae nunc bibendum maximus eleifend luctus
    enim. Sed tempus justo a sapien euismod tristique. Praesent id finibus odio.
    Integer a ornare odio, ac tempus urna. Donec mauris tellus, hendrerit
    dapibus s
  </p> -->
  <!-- <label class="d-inline-flex align-items-center gap-2 mt-3">
    <input v-model="aceite_ctt_comodato" type="checkbox" />
    <p>
      Li e aceito os
      <span class="text-gray-100"> Termos de adesão </span>.
    </p>
  </label> -->
  <!-- <button
    type="button"
    :disabled="!aceite_ctt_comodato"
    class="btn w-100 rounded-pill mt-5"
    @click.prevent="run"
  >
    <span>Assinar documento</span>
  </button> -->
</template>

<style scoped>
.btn {
  padding: 0.75rem;
  font-size: 16px;
  background-color: #272727;
  color: #fff;
}

.fullScreen {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  position: relative;
}
.fullScreen iframe {
  min-height: 100vh !important;
}
</style>
