<script setup>
import { auth } from "@/store/auth";
const authStore = auth();
</script>

<template>
  <footer class="py-4" :class="[authStore.token ? 'bg-light' : 'bg-white']">
    <div
      class="d-sm-flex justify-content-between align-items-center container text-center text-sm-left"
    >
      <p>© BEES, 2022. TODOS OS DIREITOS RESERVADOS.</p>
      <h4>BEBA COM MODERAÇÃO</h4>
    </div>
  </footer>
</template>

<style scoped>
footer {
  height: 85px;
}
h4 {
  color: #000218;
  font-weight: bold;
  font-size: 23px;
}
p {
  color: #000218;
  font-size: 16px;
  font-weight: bold;
}
</style>
