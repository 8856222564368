<script setup></script>

<template>
  <div class="d-flex align-items-center justify-content-center gap-4 mt-5">
    <img src="@/assets/img/msghelp.svg" alt="ajuda" />
    <h5>
      <strong>Precisa de ajuda?</strong> <br />
      <span class="h5">Fale com um de nossos especialistas.</span><br />
      <router-link :to="{ name: 'Contato' }" class="h6"
        >Clique aqui.</router-link
      >
    </h5>
  </div>
</template>

<style scoped>
.h5 {
  font-size: 16px !important;
}
</style>
