
import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from 'pinia'
export const app = createApp(App);
import router from "./router";
import Maska from 'maska'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/src/sweetalert2.scss'

import LayoutForm from "@/Layout/LayoutForm.vue";
import LayoutDefault from "@/Layout/LayoutDefault.vue";
import LayoutDefaultV2 from "@/Layout/LayoutDefaultV2.vue";

app.use(createPinia());
app.component('LayoutDefault', LayoutDefault)
app.component('LayoutDefaultV2', LayoutDefaultV2)
app.component('LayoutForm', LayoutForm)
app.use(router);
app.use(VueTilt);
app.use(VueSweetalert2);
app.use(ArgonDashboard);
app.use(Maska);
app.mount("#app");

app.component('VSelect', vSelect)
import Swal from "sweetalert2";
window.__Swal = Swal
