<!-- eslint-disable vue/require-prop-types -->
<script setup>
import { watch, onMounted } from "vue";

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  openModal: { Boolean, default: false },
  btnClose: { Boolean, default: true },
});

watch(
  () => props.openModal,
  () => {
    if (props.openModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
);

function handleEscape(e) {
  if (e.which === 27) {
    emit("closeModal");
  }
}

onMounted(() => {
  window.addEventListener("keydown", handleEscape);
});
</script>

<template>
  <transition name="fade">
    <div
      v-if="props.openModal"
      class="modal-wrapper"
      @click="emit('closeModal')"
      @keydown="handleEscape"
    >
      <div class="modal-conteudo card" @click.stop>
        <button
          v-if="btnClose"
          class="ms-auto bg-secondary btn btn-close"
          @click="emit('closeModal')"
        ></button>
        <slot />
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 203;
  left: 0;
  top: 0;
}

.modal-conteudo {
  width: 90%;
  max-width: 499px;
  background-color: white;
  padding: 30px;
  position: relative;
  max-height: 95vh;
  overflow: auto;
}

.btn-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
