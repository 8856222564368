<script setup>
function handleWPP() {
  const phone = "5511933058680";
  const text = "Olá, preciso de ajuda com o Bees da Chefia";
  window.open(
    `https://api.whatsapp.com/send?phone=${phone}&text=${text}`,
    "_blank"
  );
}
</script>

<template>
  <div class="d-flex gap-2 justify-content-center">
    <!-- <button class="btn chat">Chat</button> -->
    <button class="btn wpp" @click.prevent="handleWPP">
      <i class="fa-brands fa-whatsapp me-2 h5"></i>Whatsapp
    </button>
  </div>
</template>

<style scoped>
.h5 {
  font-size: 16px !important;
}
.btn.wpp {
  width: 185px;
  background-color: transparent;
  color: #282d32;
  border: solid 1px #282d32;
}
</style>
