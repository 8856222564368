<script setup>
import { ref } from "vue";
import Ajuda from "../../components/Ajuda.vue";

const contato = ref({ motivo: "" });
</script>
<template>
  <div class="area-page d-flex flex-column justify-content-between">
    <div class="form-area m-auto">
      <h1 class="text-center my-3">Fale conosco</h1>
      <select v-model="contato.motivo" class="form-control" name="n_garcom">
        <option value="" disabled selected hidden>Motivo do contado</option>
        <option :value="1">Motivo 1</option>
        <option :value="2">Motivo 2</option>
      </select>

      <textarea rows="8" class="form-control mt-2">Mensagem</textarea>

      <button type="button" class="btn w-100 rounded-pill mt-5">
        <span>Continuar</span>
      </button>
    </div>

    <Ajuda />
  </div>
</template>

<style scoped>
.area-page {
  min-height: calc(100vh - 221px);
}
h1 {
  font-size: 30px;
  color: #000218;
}

.form-area {
  width: 100%;
  max-width: 438px;
  border-radius: 10px;
}
.ver-doc {
  font-weight: bolder;
}
.doc-ass {
  color: #707070;
  font-weight: bolder;
}

.btn {
  padding: 0.75rem;
  font-size: 16px;
  background-color: #272727;
  color: #fff;
}
</style>
